<template>
    <div class="image-upload">
        <el-upload
            class="upload-demo ecert_upload"
            action="#"
            accept="image/jpeg,image/jpg,image/png"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :limit="1"
            :file-list="fileList"
            :auto-upload="false"
        >
            <el-button size="small" type="primary">{{ btnText }}</el-button>
            <!-- <template #tip>
                <div class="el-upload__tip">Top middle，只能上传 jpg/png</div>
            </template> -->
        </el-upload>
    </div>
</template>

<script>
import {ref} from 'vue'
export default {
  name: 'ImageUpload',
  props: {
      btnText: String
  },
  data(){
    return{
        imageFile: {
            text: this.btnText,
            file: {},
            url: ''
        }
    }
  },
  setup(){
    let fileList = ref([]);
    return {
        fileList
    }
  },
  components: {
  },
  computed: {
  },
  methods:{
      
      handleRemove(file, fileList) {
        this.imageFile.url = "";
        this.imageFile.file = {};
        this.$emit('imageFile', this.imageFile);
        return;
      },

      handleChange(file, fileList){
        this.getBase64(file.raw).then(res=>{
            this.imageFile.url = res;
        });
        this.imageFile.file = file;
        this.$emit('imageFile', this.imageFile)
      },

      getBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            let fileResult = "";
            reader.readAsDataURL(file);
            reader.onload = function() {
            fileResult = reader.result;
            };
            reader.onerror = function(error) {
            reject(error);
            };
            reader.onloadend = function() {
            resolve(fileResult);
            };
        });
      }

  }
}
</script>

<style scoped>
.image-upload{
    margin: 10px 0px;
}
.ecert_upload{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
}
</style>
<style>
.image-upload .el-upload-list__item{
    margin-top: 0px !important;
}

</style>
