<template>

    <nav-header></nav-header>
    <nav-sidebar></nav-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
       <div class="content">
            <router-view/>
       </div>
    </div>


</template>

<script>
import navHeader from "@/components/navHeader/NavHeader"
import navSidebar from "@/components/navSidebar/NavSidebar"
export default {
  name: 'Home',
  components: {
    navHeader,
    navSidebar
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    }
  }
}
</script>


<style>
* {
    margin: 0;
    padding: 0;
}

html,
body,
#app,
.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

a {
    text-decoration: none
}


.content-box {
    position: absolute;
    left: 250px;
    right: 0;
    top: 70px;
    bottom: 0;
    padding-bottom: 30px;
    -webkit-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
    background: #f0f0f0;
}

.content {
    width: auto;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.content-collapse {
    left: 65px;
}

.container {
    padding: 30px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-25{
  margin-bottom: 25px;
}
.mb-30{
  margin-bottom: 30px;
}

.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.mt-25{
  margin-top: 25px;
}
.mt-30{
  margin-top: 30px;
}

.page-header{
    padding: 20px 0px;
}
</style>
