import { createApp } from 'vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import Print from 'vue3-print-nb'
import * as echarts from 'echarts'
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue'

const app = createApp(App).use(store).use(router).use(ElementPlus).use(Print).mount('#app')

app.echarts=echarts

