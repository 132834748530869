<template>
    <div class="nav-sidebar">
                <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                v-if="subItem.subs"
                                :index="subItem.index"
                                :key="subItem.index"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.index"
                                :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'nav_sidebar',
    data() {
        return {
            items: [
                {
                    icon: "el-icon-s-home",
                    index: "/dashboard",
                    title: "Dashboard"
                },
                {
                    icon: "el-icon-tickets",
                    index: "ecert",
                    title: "ECert",
                    subs: [
                        {
                            index: "/ecert/list",
                            title: "Cert Listing"
                        },
                        {
                            index: "/ecert/group",
                            title: "Cert Group"
                        },
                        {
                            index: "/ecert/approval",
                            title: "Cert Approval"
                        }
                    ]
                },

                {
                    icon: "el-icon-toilet-paper",
                    index: "account",
                    title: "Account",
                    subs:[
                        {
                            index: '/account/user',
                            title: "Users"
                        },{
                            index: '/account/authority',
                            title: "Authority"
                        }
                    ]
                },
                {
                    icon: "el-icon-data-analysis",
                    index: "/credits/main",
                    title: "Credits"
                },
                // {
                //     icon: "el-icon-circle-close",
                //     index: "7",
                //     title: "错误处理",
                //     subs: [
                //         {
                //             index: "permission",
                //             title: "权限测试"
                //         },
                //         {
                //             index: "404",
                //             title: "404页面"
                //         }
                //     ]
                // }
            ]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace("", "");
        },
        collapse(){
            return this.$store.state.collapse
        }
    }
};
</script>
<style scoped>
.nav-sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.nav-sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.nav-sidebar > ul {
    height: 100%;
}
</style>