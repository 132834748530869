import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard/Dashboard.vue')
  },
  {
    path: '/ecert/list',
    name: 'ecertList',
    component: () => import('../views/eCert/CertList.vue')
  },
  {
    path: '/ecert/group',
    name: 'ecertGroup',
    component: () => import('../views/eCert/CertGroup.vue')
  },
  {
    path: '/ecert/approval',
    name: 'ecertApproval',
    component: () => import('../views/eCert/CertApproval.vue')
  },
  {
    path: '/ecert/details',
    name: 'ecertDetails',
    component: () => import('../views/eCert/CertDetails.vue')
  },
  {
    path: '/credits/main',
    name: 'creditsMain',
    component: () => import('../views/credits/CreditsMain.vue')
  },
  {
    path: '/account/user',
    name: 'accountUser',
    component: () => import('../views/account/User.vue')
  },
  {
    path: '/account/authority',
    name: 'accountAuthority',
    component: () => import('../views/account/Authority.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
